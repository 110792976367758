body {
  margin: 0;
  font-family: 'DINPro', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  //font-size: 21px;
  //line-height: 1.4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.alert-success {
  color: #15241f;
  background: none;
  border-color: #d0d3d2;
}

.alert-warning {
  color: #15241f;
//  color: #eb6d01;
  background: none;
  border-color: #d0d3d2;
}
.alert-error {
  color: #15241f;
//  color: #d13b3b;
  background: none;
  border-color: #d0d3d2;
}

.alert-warning-text {
  color: #eb6d01;
}

.alert-error-text {
  color: #d13b3b;
}

.alert-success-inverted {
  color: white;
  background-color: lighten(#99c031, 10%);
  border-color: darken(#d0d3d2, 10%);
}

.alert-warning-inverted {
  color: white;
  background-color: lighten(#eb6d01, 10%);
  border-color: darken(#eb6d01, 10%);
}
.alert-error-inverted {
  color: white;
  background-color: lighten(#d13b3b, 10%);
  border-color: darken(#d13b3b, 10%);
}

.smappee-logo {
  width: 200px;
  margin-bottom: 2em;
}

.main-container {
  max-width: 800px;
  min-height: calc(100vh - 107px - 2em);
  margin-top: 2em;
  position: relative;
}

@media screen and (max-width: 1240px) {
  .smappee-logo {
    width: 100px;
    margin-top: 0.5em;
  }

  .main-container {
    margin-top: 1em;
    min-height: calc(100vh - 107px - 1em);
  }
}
