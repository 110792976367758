.noIncidents {
  font-style: italic;
  color: #888;
}

.dayHeader {
  font-size: 1.5rem;
  border-bottom: 1px solid #ccc;
}

.resolved {
  font-style: italic;
  color: #888;
}

.warning {
  color: #888;

  .resolved {
    color: #ccc;
  }
}
