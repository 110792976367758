@font-face {
  font-family: 'DINPro';
  src: url("./fonts/DINPro/DINPro-Bold.eot");
  src: url("./fonts/DINPro/DINPro-Bold.eot?#iefix") format("embedded-opentype"),
       url("./fonts/DINPro/DINPro-Bold.woff2") format("woff2"),
       url("./fonts/DINPro/DINPro-Bold.woff") format("woff"),
       url("./fonts/DINPro/DINPro-Bold.ttf") format("truetype"),
       url("./fonts/DINPro/DINPro-Bold.svg#DINPro-Bold") format("svg");
  font-weight: bold;
}

@font-face {
  font-family: 'DINPro';
  src: url("./fonts/DINPro/DINPro-Light.eot");
  src: url("./fonts/DINPro/DINPro-Light.eot?#iefix") format("embedded-opentype"),
       url("./fonts/DINPro/DINPro-Light.woff2") format("woff2"),
       url("./fonts/DINPro/DINPro-Light.woff") format("woff"),
       url("./fonts/DINPro/DINPro-Light.ttf") format("truetype"),
       url("./fonts/DINPro/DINPro-Light.svg#DINPro-Light") format("svg");
  font-weight: 200;
}

@font-face {
  font-family: 'DINPro';
  src: url("./fonts/DINPro/DINPro-Medium.eot");
  src: url("./fonts/DINPro/DINPro-Medium.eot?#iefix") format("embedded-opentype"),
       url("./fonts/DINPro/DINPro-Medium.woff2") format("woff2"),
       url("./fonts/DINPro/DINPro-Medium.woff") format("woff"),
       url("./fonts/DINPro/DINPro-Medium.ttf") format("truetype"),
       url("./fonts/DINPro/DINPro-Medium.svg#DINPro-Medium") format("svg");
  font-weight: 300;
}

@font-face {
  font-family: 'DINPro';
  src: url("./fonts/DINPro/DINPro-Regular.eot");
  src: url("./fonts/DINPro/DINPro-Regular.eot?#iefix") format("embedded-opentype"),
       url("./fonts/DINPro/DINPro-Regular.woff2") format("woff2"),
       url("./fonts/DINPro/DINPro-Regular.woff") format("woff"),
       url("./fonts/DINPro/DINPro-Regular.ttf") format("truetype"),
       url("./fonts/DINPro/DINPro-Regular.svg#DINPro-Regular") format("svg");
  font-weight: normal;
}
