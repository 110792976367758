.wrapper {
  background: white;
  
  //position: fixed;
  //bottom: 0px;
}

.wrapper2 {
  display: flex;
  justify-content: center;
  margin-left: 1em;
  margin-right: 1em;
}

.footer {
  display: flex;
  flex-wrap: wrap;

  font-size: 16px;
  font-weight: 200;
  line-height: 27px;

  padding-top: 22px;
  padding-bottom: 22px;

  color: #15241f;
  text-align: center !important;

  ul {
    list-style-type: none;
    margin: 0;
    margin-left: 0px;
    padding: 0;

    display: flex;
    flex-wrap: wrap;
    padding-left: 0;

    li {
      padding-left: 5px;
      padding-right: 5px;

      a {
        color: #15241f;
        text-decoration: underline;

        &:hover {
          color: #959897
        }
        &:active, &:visited {
          color: #15241f;
        }
      }
    }
  }
}

.footer2 {
  padding: 6px 0;
  background-color: #155a55 !important;
  text-align: center;
  color: white;
}
