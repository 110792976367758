.button {
  color: #fff;
  background-color: #155a55;
  border: 2px solid #155a55;

  &:hover, &:active, &:focus {
    background-color: #0e3b38 !important;
    border: 2px solid #0e3b38 !important;
  }

  &:focus {
    box-shadow: 0 0 0 .2rem rgba(21,90,85,.25) !important;
  }
}

.indicatesRequired {
  text-align: right;
  padding-bottom: 10px;
}

.asterisk {
  color: red;
}

.signup {
  background:#fff;
  clear:left;
  font:14px Helvetica,Arial,sans-serif;
}

.form input[type=checkbox] {
  display: inline;
  margin-right: 10px;
}

.gdpr {
  margin-top: 20px;

  color: #4a4a4a;
  font-size: 15px;
  line-height: 1.5;
}

.gdprTitle {
  line-height: 150%;
  font-family: Helvetica;
  font-size: 18px;
  color: #15241F;
  font-weight: bold;
}

.gdpr fieldset label {
  font-weight: normal;
}

.form .fieldset{
  border:none;min-height: 0px;padding-bottom:0px;
}

.gdprFooter {
  padding: 20px;
  background: rgba(255, 255, 255, 0.9);
  overflow: auto;
  color: #6d6d6d;

  line-height: 150%;
  font-family: Helvetica;
  font-size: 14px;

  img {
    width: 65px;
    float: left;
    margin-right: 15px;
  }
}
