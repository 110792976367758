
.text {
  p:last-child {
    margin-bottom: 0;
  }
}

.textWarning {
  color: gray;

  p:last-child {
    margin-bottom: 0;
  }
}

.resolution {
  margin-top: 0.5rem;
  font-weight: bold;
  font-style: italic;
}
